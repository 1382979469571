export const state = () => ({
    domainUrl: null, // определяется автоматически, не менять

    prodDomain: 'dvizhenie.ru',

    protocol: 'https',
});

export const getters = {
    getDomainAddress(state) {
        return `${state.protocol}://${state.domainUrl}`;
    },

    getIsTestStage(state) {
        return state.prodDomain !== state.domainUrl;
    },
};

export const actions = {
    async init({ dispatch, state }, headers) {
        if (headers['x-forwarded-host']) {
            dispatch('changeActiveDomain', headers['x-forwarded-host']);
        } else if (process.env.PROXY_URL) {
            const regex = /^(\w+):\/\/(?:www.)?([^/]+)/g;
            const array = [...process.env.PROXY_URL.matchAll(regex)];
            const found = array.reduce((acc, current) => [...acc, current[1], current[2]], []);
            const protocol = found[0];
            const domain = found[1];

            if (protocol) {
                dispatch('changeActiveProtocol', protocol);
            }

            if (domain) {
                dispatch('changeActiveDomain', domain);
            }
        } else {
            console.warn('[store domain/actions/init Error] domain address is not set');
        }
    },

    changeActiveDomain({ commit }, payload) {
        commit('SET_DOMAIN', payload);
    },

    changeActiveProtocol({ commit }, payload) {
        commit('SET_PROTOCOL', payload);
    },
};

export const mutations = {
    SET_DOMAIN(state, domain) {
        state.domainUrl = domain;
    },

    SET_PROTOCOL(state, payload) {
        state.protocol = payload;
    },
};

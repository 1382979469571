export const state = () => ({
    documents: [],
    settings: {},
});

export const getters = {};

export const actions = {
    async nuxtServerInit({ dispatch }, context) {
        try {
            await Promise.all([
                dispatch('domain/init', context.req.headers),
                dispatch('device/init', context.req.headers),
                dispatch('device/setYaMetricsObserverState', context.req?.serverReferer),
                dispatch('media/fetchSpecs', context),
                dispatch('fetchDocuments'),
                dispatch('fetchSettings'),
                dispatch('contacts/fetchContacts'),
                dispatch('latestNews/fetchFeed'),
                dispatch('latestNews/fetchAds'),
                dispatch('user/fetchUser'),
                dispatch('responses/fetchInit'),
            ]);
        } catch (e) {
            console.error('[nuxtServerInit]: ', e);
        }
    },

    async fetchDocuments({ commit }) {
        try {
            const res = await this.$axios.$get(this.$api.documents.list, {
                params: {
                    location: 1,
                },
            });

            commit('SET_DOCUMENTS', res);
        } catch (e) {
            console.warn();
        }
    },

    async fetchSettings({ commit }) {
        try {
            const res = await this.$axios.$get(this.$api.settings.data);

            commit('SET_SETTINGS', res);
        } catch (e) {
            console.warn();
        }
    },
};

export const mutations = {
    SET_DOCUMENTS(state, payload) {
        state.documents = payload;
    },

    SET_SETTINGS(state, payload) {
        state.settings = payload;
    },
};

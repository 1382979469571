export const state = () => ({
    specs: {},
    types: {},
});

export const getters = {
    getTypes(state) {
        return state.types;
    },

    getSpecs(state) {
        return state.specs;
    },
};

export const actions = {
    async fetchSpecs({ commit }, context) {
        try {
            const specsRes = await context.$axios.$get(context.$api.media.specs);

            const specs = specsRes?.reduce((acc, el) => {
                acc[el.name] = el.choices;
                return acc;
            }, {}) || {};

            const types = specs?.type?.reduce((acc, el) => {
                acc[el.label] = el.value;
                return acc;
            }, {}) || {};

            commit('SET_SPECS', specs);
            commit('SET_TYPES', types);
        } catch (e) {
            console.warn();
        }
    },
};

export const mutations = {
    SET_SPECS(state, payload) {
        state.specs = payload;
    },

    SET_TYPES(state, payload) {
        state.types = payload;
    },
};

const daysForShowUnread = 7;

export const state = () => ({
    loading: true,
    specs: {},
    list: [],
    savedNotificationsStatuses: [],
    dateLastOpening: null,
});

export const getters = {
    getLoading(state) {
        return state.loading;
    },

    getStatuses(state) {
        return state.specs?.choices || [];
    },

    getList(state) {
        return state.list;
    },

    hasUnreadNotifications(state) {
        if (!state.list.length) {
            return false;
        }

        return Boolean(state.list.find(item => typeof item.isRead !== 'undefined' && !item.isRead));
    },

    isShowUnreadForUnregistered(state) {
        if (!state.dateLastOpening) {
            return true;
        }

        const savedDate = Date.parse(state.dateLastOpening);
        const todayDate = Date.parse(new Date());

        const Difference_In_Time = todayDate - savedDate;

        const Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

        return Difference_In_Days >= daysForShowUnread;
    },
};

export const actions = {
    async fetchInit({ rootState, commit, dispatch }) {
        try {
            if (!rootState?.user?.user) {
                return;
            }

            const api = rootState?.user?.user?.is_company ?
                this.$api.requestsVacancy :
                this.$api.requestsResume;

            const resSpecs = await this.$axios.$get(api.specs);
            commit('SET_SPECS', resSpecs[0] || {});

            const limit = resSpecs[0]?.choices.reduce((acc, item) => acc + item.count, 0);

            const resList = await this.$axios.$get(api.list, {
                params: {
                    limit: limit,
                },
            });

            commit('SET_LIST', resList?.results || []);

            if (process.client) {
                dispatch('loadStatusesFromLocalStorage');
            }
        } catch (e) {
            console.error('responses/fetchList: ', e);
        }
    },

    getLocalStorageDateForUnregistered({ commit }) {
        if (process.server) {
            return null;
        }

        const localStorageData = JSON.parse(localStorage.getItem('notificationsDateOpen')) || null;

        commit('SET_DATE_LAST_OPENING', localStorageData);

        return localStorageData;
    },

    setLocalStorageTodayDateForUnregistered({ commit }) {
        if (process.server) {
            return;
        }

        localStorage.setItem('notificationsDateOpen', JSON.stringify(new Date()));

        commit('SET_DATE_LAST_OPENING', new Date());
    },

    async loadStatusesFromLocalStorage({ state, commit, dispatch }) {
        if (process.server) {
            return;
        }

        const localStorageData = await dispatch('getLocalStorage');
        let list = JSON.parse(JSON.stringify(state.list));

        list = list.map(item => {
            if (localStorageData.find(savedItem => savedItem.id === item.id && savedItem.answer_status === item.answer_status)) {
                item.isRead = true;
            } else {
                item.isRead = false;
            }

            return item;
        });

        commit('SET_SAVED_NOTIFICATIONS_STATUSES', localStorageData);
        commit('SET_LIST', list || []);
    },

    getLocalStorage({ commit }) {
        if (process.server) {
            return [];
        }

        const localStorageData = JSON.parse(localStorage.getItem('notifications')) || [];

        commit('SET_SAVED_NOTIFICATIONS_STATUSES', localStorageData);

        return localStorageData;
    },

    setLocalStorage({ state }) {
        localStorage.setItem('notifications', JSON.stringify(state.savedNotificationsStatuses));
    },

    readAllNotifications({ state, commit, dispatch }) {
        const list = JSON.parse(JSON.stringify(state.list));
        let localStorageData = [];

        localStorageData = list.map(item => ({
            id: item.id,
            answer_status: item.answer_status,
        }));

        list.forEach(item => item.isRead = true);

        commit('SET_SAVED_NOTIFICATIONS_STATUSES', localStorageData);
        commit('SET_LIST', list);

        dispatch('setLocalStorage');
    },

    addReadNotification({ state, commit, dispatch }, payload) {
        const list = JSON.parse(JSON.stringify(state.list));
        const localStorageData = JSON.parse(JSON.stringify(state.savedNotificationsStatuses));

        localStorageData.push(payload);
        list.find(item => item.id === payload.id).isRead = true;

        commit('SET_LIST', list);
        commit('SET_SAVED_NOTIFICATIONS_STATUSES', localStorageData);

        dispatch('setLocalStorage');
    },
};

export const mutations = {
    SET_SPECS(state, payload) {
        return state.specs = payload;
    },

    SET_LIST(state, payload) {
        payload = payload.map(item => {
            if (!item.answer_status) {
                item.answer_status = 'empty';
            }

            return item;
        });

        state.loading = false;
        return state.list = payload;
    },

    SET_SAVED_NOTIFICATIONS_STATUSES(state, payload) {
        state.savedNotificationsStatuses = payload;
    },

    SET_DATE_LAST_OPENING(state, payload) {
        state.dateLastOpening = payload;
    },

    RESET_ALL(state) {
        state.list = [];
    },
};

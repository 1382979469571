export const state = () => ({
    isVisible: true, // Видимость футера
});

export const actions = {
    changeVisibility({ commit }, val) {
        commit('CHANGE_VISIBILITY', val);
    },
};

export const mutations = {
    CHANGE_VISIBILITY(state, payload) {
        state.isVisible = payload;
    },
};

const limit = 6;

export const state = () => ({
    feedList: [],
    feedPagination: {
        page: 1,
        limit: limit,
        hasNext: false,
        isLoad: false,
    },
    companiesList: [],
    companiesPagination: {
        page: 1,
        limit: limit,
        hasNext: false,
        isLoad: false,
    },
    adsList: [],
});

export const getters = {
    getFeedList(state) {
        return state.feedList;
    },

    getFeedPagination(state) {
        return state.feedPagination;
    },

    getCompaniesList(state) {
        return state.companiesList;
    },

    getCompaniesPagination(state) {
        return state.companiesPagination;
    },

    getAdsList(state) {
        return state.adsList;
    },
};

export const actions = {
    async fetchFeed({ commit }) {
        try {
            const feedRes = await this.$axios.$get(this.$api.media.latest, {
                params: {
                    limit: limit,
                },
            });

            commit('SET_FEED_LIST', feedRes?.results || []);
            commit('SET_FEED_PAGINATION', {
                page: 1,
                limit: limit,
                hasNext: Boolean(feedRes?.next),
                isLoad: false,
            });
        } catch (e) {
            console.error('latestNews/fetchFeed: ', e);
        }
    },

    async fetchMoreFeed({ state, commit }) {
        try {
            if (!state.feedPagination.hasNext) {
                return;
            }

            commit('SET_FEED_PAGINATION', {
                ...state.feedPagination,
                isLoad: true,
            });

            const feedRes = await this.$axios.$get(this.$api.media.latest, {
                params: {
                    limit: state.feedPagination.limit,
                    offset: state.feedPagination.page * state.feedPagination.limit,
                },
            });

            commit('SET_FEED_LIST', [...state.feedList, ...feedRes?.results || []]);
            commit('SET_FEED_PAGINATION', {
                page: state.feedPagination.page + 1,
                limit: state.feedPagination.limit,
                hasNext: Boolean(feedRes?.next),
                isLoad: false,
            });
        } catch (e) {
            console.error('latestNews/fetchMoreFeed: ', e);
        }
    },

    async fetchCompanies({ commit }) {
        try {
            const companiesRes = await this.$axios.$get(this.$api.media.company, {
                params: {
                    limit: limit,
                },
            });

            commit('SET_COMPANIES_LIST', companiesRes?.results || []);
            commit('SET_COMPANIES_PAGINATION', {
                page: 1,
                limit: limit,
                hasNext: Boolean(companiesRes?.next),
                isLoad: false,
            });
        } catch (e) {
            console.error('latestNews/fetchCompanies: ', e);
        }
    },

    async fetchMoreCompanies({ state, commit }) {
        try {
            if (!state.companiesPagination.hasNext) {
                return;
            }

            commit('SET_COMPANIES_PAGINATION', {
                ...state.companiesPagination,
                isLoad: true,
            });

            const companiesRes = await this.$axios.$get(this.$api.media.company, {
                params: {
                    limit: state.companiesPagination.limit,
                    offset: state.companiesPagination.page * state.companiesPagination.limit,
                },
            });

            commit('SET_COMPANIES_LIST', [...state.companiesList, ...companiesRes?.results || []]);
            commit('SET_COMPANIES_PAGINATION', {
                page: state.companiesPagination.page,
                limit: state.companiesPagination.limit,
                hasNext: Boolean(companiesRes?.next),
                isLoad: false,
            });
        } catch (e) {
            console.error('latestNews/fetchMoreCompanies: ', e);
        }
    },

    async fetchAds({ commit }) {
        try {
            const adsRes = await this.$axios.$get(this.$api.advertiser.adsAside);

            commit('SET_ADS_LIST', adsRes || []);
        } catch (e) {
            console.error('latestNews/fetchAds: ', e);
        }
    },

    resetData({ commit }) {
        commit('RESET_ALL');
    },
};

export const mutations = {
    SET_FEED_LIST(state, payload) {
        payload = payload.map(item => {
            const newItem = { ...item };
            delete newItem.type;
            return newItem;
        });

        return state.feedList = payload;
    },

    SET_FEED_PAGINATION(state, payload) {
        return state.feedPagination = payload;
    },

    SET_COMPANIES_LIST(state, payload) {
        payload = payload.map(item => {
            const newItem = { ...item };
            delete newItem.type;
            return newItem;
        });

        return state.companiesList = payload;
    },

    SET_COMPANIES_PAGINATION(state, payload) {
        return state.companiesPagination = payload;
    },

    SET_ADS_LIST(state, payload) {
        return state.adsList = payload;
    },

    RESET_ALL(state) {
        state.feedList = state.feedList.slice(0, limit);
        state.feedPagination ={
            page: 1,
            limit: limit,
            hasNext: state.feedPagination.page > 1,
            isLoad: false,
        };
        state.companiesList = state.companiesList.slice(0, limit);
        state.companiesPagination = {
            page: 1,
            limit: limit,
            hasNext: state.companiesPagination.page > 1,
            isLoad: false,
        };
    },
};

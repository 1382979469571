export const state = () => ({
    isScrolled: false, // Производится ли скролл вообще
    isVisible: true, // Отображение и скрытие меню
    isMenuModalOpened: false,
    isProfileMenuOpened: false,
    profileTitle: [],
});

export const actions = {
    setScrolled({ commit }, val) {
        commit('SET_SCROLLED', val);
    },

    changeVisibility({ commit }, val) {
        commit('CHANGE_VISIBILITY', val);
    },

    setMenuModalOpened({ commit }, val) {
        commit('SET_MENU_MODAL_OPENED', val);
    },

    setProfileMenuOpened({ commit }, val) {
        commit('SET_PROFILE_MENU_OPENED', val);
    },

    setProfileTitle({ commit }, val) {
        commit('SET_PROFILE_TITLE', val);
    },
};

export const mutations = {
    SET_SCROLLED(state, payload) {
        state.isScrolled = payload;
    },

    CHANGE_VISIBILITY(state, payload) {
        state.isVisible = payload;
    },

    SET_MENU_MODAL_OPENED(state, payload) {
        state.isMenuModalOpened = payload;
    },

    SET_PROFILE_MENU_OPENED(state, payload) {
        state.isProfileMenuOpened = payload;
    },

    SET_PROFILE_TITLE(state, payload) {
        state.profileTitle = payload;
    },
};

export const state = () => ({
    contacts: {},
});

export const actions = {
    async fetchContacts({ commit }) {
        try {
            const contactsRes = await this.$axios.$get(this.$api.contacts.data);
            commit('SET_CONTACTS', contactsRes);
        } catch (e) {
            console.warn('[contacts/fetchContacts] GET request failed: ', e);
        }
    },
};

export const mutations = {
    SET_CONTACTS(state, payload) {
        state.contacts = payload;
    },
};
